@font-face {
    font-family: 'Monaco';
    font-style: normal;
    font-weight: normal;
    src: local('Monaco'), url('../assets/fonts/Monaco.woff') format('woff');
}

p{
    @apply text-sm text-gray-600;
}
a {
    @apply text-sm font-medium text-blue transition-all ease-in-out duration-200 hover:text-red
}


