
@font-face {
    font-family: 'Monaco';
    font-style: normal;
    font-weight: normal;
    src: local('Monaco'), url(/static/media/Monaco.85644e18.woff) format('woff');
}

p {
    font-size: 0.875rem;
    line-height: 1.25rem;
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
}
a {
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 500;
    --tw-text-opacity: 1;
    color: rgba(1, 112, 243, var(--tw-text-opacity));
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    transition-duration: 200ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
a:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 0, 0, var(--tw-text-opacity));
}



input[type='text'], input[type='email'], input[type='password'] {
    display: block;
    width: 100%;
    -webkit-appearance: none;
            appearance: none;
    border-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem
}
input[type='text']::-webkit-input-placeholder, input[type='email']::-webkit-input-placeholder, input[type='password']::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity))
}
input[type='text']:-ms-input-placeholder, input[type='email']:-ms-input-placeholder, input[type='password']:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity))
}
input[type='text']::placeholder, input[type='email']::placeholder, input[type='password']::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity))
}
input[type='text'], input[type='email'], input[type='password'] {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}
input[type='text']:focus, input[type='email']:focus, input[type='password']:focus {
    --tw-border-opacity: 1;
    border-color: rgba(1, 112, 243, var(--tw-border-opacity));
    outline: 2px solid transparent;
    outline-offset: 2px;
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(1, 112, 243, var(--tw-ring-opacity))
}
@media (min-width: 640px) {
    input[type='text'], input[type='email'], input[type='password'] {
        font-size: 0.875rem;
        line-height: 1.25rem
    }
}

/*@tailwind base;*/
/*@tailwind components;*/
/*@tailwind utilities;*/

.property {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity))
}
.string {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity))
}
.number {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity))
}
.boolean {
    font-weight: 900;
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity))
}

